<template>
<div>
  <b-container fluid class="mb-3" v-if="!loading">
    <h4 class="card-title">{{item.name}}</h4>
    <div>
      {{ item.definition }}
    </div>
    <div v-if="item.metrics.length > 0">
      <strong>Metrics</strong>
      <div v-for="metric in item.metrics" :key="item.id + '-' + metric.id">
        <router-link :to="{ name: 'Metric', params: { id: metric.id }}">{{ metric.name }}</router-link>
      </div>
    </div>
  </b-container>
</div>
</template>

<script>
import delay from '@/libs/delay'

export default {
  name: 'DataElement',
  components: {
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    }
  },
  created: function () {
    console.log('trigger created')
    this.load()
  },
  data () {
    const data = {
      item: {},
      loading: true
    }
    return data
  },
  methods: {
    load: async function () {
      console.log('trigger load')
      this.loading = true
      this.item = this.dataelements.find(item => item.id === Number.parseInt(this.$route.params.id))
      console.log(this.dataelements)
      console.log(this.item)
      await delay(100)
      this.loading = false
    }
  },
  watch: {
    '$route': function () {
      this.load()
    }
  }
}
</script>
